import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Social = () => (
  <Layout>
    <SEO title="Social" />

    <h1>Social</h1>
  </Layout>
)

export default Social
